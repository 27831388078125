<template>
    <template v-if="loading">
        <LoadingComponent />
    </template>
    <template v-else>
        <SendSongRequest :requestSongs="requestSongs" />
        <RatingComponent :hostId="event.host_id" :host="event.host" />
        <template v-if="event.host">
            <TipModal :hostId="event.host_id" :host="event.host" />
        </template>

        <div>
            <div class="d-flex justify-content-between align-items-center">
                <button v-if="role != 4" @click="goBack"
                    class="btn bg-neutral-800 text-neutral-25 ps-3 pe-3 pt-2 pb-2"><i
                        class="fa-solid fa-arrow-left"></i>
                    <span class="ms-2">Go Back</span> </button>
                <template>
                    <button class="btn btn-primary text-neutral-25 ps-3 pe-3 pt-2 pb-2"
                        @click="openModal('eventEditModal')"><i class="fa-solid fa-file-pen"></i><span
                            class="ms-3">Edit</span></button>
                </template>
            </div>
            <div class="mt-3">
                <img class="cover-image position-relative rounded " :src="event.banner_path || require('@/assets/util/images/no_event_banner.jpg')" alt="">
                <div class="container  mt-5 ">
                    <div class="row ">
                        <div class=" col-12 col-md-12 col-lg-9">
                            <div class="d-flex justify-content-between  align-items-start">
                                <div>
                                    <h3 class="text-neutral-25 event-title text-bold ">{{ event.name }}</h3>
                                </div>
                                <div class="d-flex gap-2">
                                    <button :disabled="event.joined" @click="joinEvent"
                                        class="btn btn-primary text-neutral-25 font-weight-bold text-4 d-flex align-items-center gap-2"><i
                                            class="fa-solid fa-user-plus"></i> <span class="text-4">
                                                <template v-if="!event.joined">
                                                    Join <span class="d-none d-lg-inline-block">Event</span>
                                                </template>
                                                <template v-else>
                                                    Joined
                                                </template>
                                            </span>
                                    </button>
                                    <button @click="openModal('qrcodeModal')"
                                        class="btn bg-white text-neutral-800 font-weight-bold text-4 d-flex align-items-center gap-2"><i
                                            class="fa-solid fa-qrcode"></i> <span class="text-4 d-none d-lg-inline-block">Share</span> </button>
                                    <QrcodeModal :src="event.qrcode_path" :code="event.code"
                                        :basePath="event.qrcode_base_path" :url="event.url" :type="'event'" />
                                </div>
                            </div>
                            <div class="container p-0 mt-3">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                        <div
                                            class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                            <div>
                                                <div
                                                    class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                    {{ event.start_date_month }}</div>
                                                <div class="mt-3">
                                                    <h6 class="text-neutral-25 text-center">Event Date</h6>
                                                    <p class="text-center">
                                                        <span class="text-neutral-25 text-3 text-neutral-200">{{
        event.formatted_start_time }} - </span>
                                                        <span class="text-neutral-25 text-3 text-neutral-200">{{
        event.formatted_end_time }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                        <div
                                            class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                            <div>
                                                <div
                                                    class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                    <i class="fa-solid fa-location-dot"></i>
                                                </div>
                                                <div class="mt-3">
                                                    <h6 class="text-neutral-25 text-center">Location</h6>
                                                    <p class="text-center"><span
                                                            class="text-neutral-25 text-3 text-neutral-200 text-break">{{
        event.address || 'N/A'
    }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                        <div
                                            class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                            <div>
                                                <div
                                                    class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                    <i class="fa-solid fa-qrcode"></i>
                                                </div>
                                                <div class="mt-3">
                                                    <h6 class="text-neutral-25 text-center">Joined</h6>
                                                    <p class="text-center"><span
                                                            class="text-neutral-25 text-20 text-neutral-200">{{
            event.enrolled_users_count }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                        <div
                                            class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                            <div>
                                                <div
                                                    class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                    <i class="fa-solid fa-music"></i>
                                                </div>
                                                <div class="mt-3">
                                                    <h6 class="text-neutral-25 text-center">Song Request</h6>
                                                    <p class="text-center"><span
                                                            class="text-neutral-25 text-20 text-neutral-200">{{
        event.song_requests_count }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="event.joined" class="mt-4">
                                <div class="d-flex justify-content-center">
                                    <h6
                                        class="bg-primary px-4 py-2 text-neutral-25 text-5 text-bold tab-title song-name">
                                        Song Request</h6>
                                </div>
                                <div class="d-flex align-items-center gap-2 mt-2">
                                    <h6 class="text-neutral-25 text-20 font-weight-600">Requested Song</h6>
                                    <button @click="openModal('sendSongRequestModal')"
                                        class="btn btn-primary text-neutral-25"><i
                                            class="fa-solid fa-plus"></i></button>
                                </div>
                                <div class="mt-3">
                                    <div class="row">
                                        <template v-if="requestSongs.length">
                                            <div v-for="(song, index) in requestSongs" :key="index"
                                                class="col-6 col-sm-6  col-md-4 col-lg-4 col-xl-3 position-relative mb-3">
                                                <SongRequestCard :song="song" />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-12">
                                                <NoData />
                                            </div>
                                        </template>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12 col-lg-3">
                            <div class="row">
                                <div class="mb-3 col-12 col-md-6 col-lg-12">
                                    <div class="rounded bg-neutral-800  pb-2">
                                        <h2 class="text-neutral-200 text-20 pt-3 font-weight-600 ps-3">Host</h2>
                                        <div>
                                            <img height="110"
                                                class="object-fit-cover host-cover-image position-relative pt-2 ps-2 pe-2 rounded"
                                                :src="event?.host?.cover_photo_path || require('@/assets/util/images/cover.jpg')"
                                                alt="">
                                            <div class="profile">
                                                <img class="host-profile-image rounded-circle position-absolute"
                                                    :src="event?.host?.profile_image_path || require('@/assets/util/images/avatar.png')"
                                                    alt="">
                                            </div>
                                        </div>
                                        <h5 class="text-neutral-25 text-20 text-center mt-5">{{
    event?.host?.display_name }}
                                        </h5>
                                        <p class="text-neutral-300 ps-3 pe-3 text-center">
                                            {{ event?.host?.bio && event.host.bio.length > 100 ?
    event.host.bio.slice(0,
                                            100) + '...' : event?.host?.bio }}
                                        </p>

                                        <router-link
                                            :to="{ name: 'profile.details', params: { hostId: event?.host?.id} }"
                                            class="btn btn-primary text-neutral-25 ms-2 me-2 mt-4  view-profile d-flex align-items-center justify-content-center gap-3">
                                            <span>View
                                                Profile</span> <i class="fa-solid fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 col-lg-12 mb-3">
                                    <div class="rounded bg-neutral-800 pb-2">
                                        <h2 class="text-neutral-200 text-20 pt-3   font-weight-600 ps-3">Tips</h2>
                                        <p class="text-neutral-300 ps-3 pe-3 text-start">You can extend your
                                            gratitude
                                            and
                                            support your favorite host by tipping</p>
                                        <button @click="openModal('TipModal')"
                                            class="btn btn-primary text-neutral-25 ms-2 me-2 mt-4  view-profile d-flex align-items-center justify-content-center gap-3"><span>Tip
                                                Host</span> <i class="fas fa-dollar-sign"></i></button>
                                    </div>
                                </div>
                                <div v-if="!isRated" class="col-12 col-md-6 col-lg-12 mb-3">
                                    <div class="rounded bg-neutral-800 pb-2 ">
                                        <h2 class="text-neutral-200 text-20 pt-3  font-weight-600 ps-3">Rate The
                                            Host
                                        </h2>
                                        <p class="text-neutral-300 ps-3 pe-3 text-start">If you enjoyed the event
                                            then rate
                                            the host </p>
                                        <button
                                            v-on:click="role == 4 ? loginAlert.loginAlert() : openModal('ratingModal')"
                                            class="btn btn-primary text-neutral-25 ms-2 me-2 mt-4  view-profile d-flex align-items-center justify-content-center gap-3"><span>Rate
                                                Host</span> <i class="fa-regular fa-star"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <QrcodeModal :src="event.qrcode_path" :code="event.code" :basePath="event.qrcode_base_path" :url="event.url"
            :type="'profile'" />
    </template>
    <LoginAlert ref="loginAlert" />
</template>

<script>
import store from '@/store/index.js';
import { ref, computed, onMounted, inject, watch } from 'vue';
import { useRoute } from 'vue-router';
import SendSongRequest from '@/components/Attendee/Event/SendSongRequest.vue';
import RatingComponent from '@/components/Attendee/Event/RatingComponent.vue';
import TipModal from '@/components/Attendee/Event/TipModal.vue';
import { openModal } from '@/assets/js/utils';
import { useToast } from 'vue-toastification';
import QrcodeModal from '@/components/Host/Event/QrcodeModal.vue';
import LoadingComponent from '@/components/Shared/LoadingComponent.vue';
import NoData from '@/components/Shared/NoData.vue';
import SongRequestCard from '@/components/Shared/SongRequestCard.vue';
import LoginAlert from '@/components/Shared/LoginAlert.vue';

export default ({
    components: {
        SendSongRequest,
        RatingComponent,
        TipModal,
        QrcodeModal,
        LoadingComponent,
        NoData,
        SongRequestCard,
        LoginAlert
    },
    setup() {
        const loginAlert = ref(null);
        const router = inject('router');
        const route = useRoute();
        const toast = useToast();
        const role = computed(() => store.getters['user/getUserRole']);
        const swal = inject('$swal');
        const qrValue = route.query.qr;
        if (qrValue == 'true') {
            toast.error('Please login first and scan the qr code.');
        }
        const eventId = route.params.eventId;
        const loading = ref(true);
        const searchTerm = ref('');
        const requestSongs = ref([]);
        const event = computed(() => store.getters['attendeeEvent/attendeeEventDetailsData']);
        const isRated = computed(() => store.getters['attendeeEvent/getIsRatedData']);
                const fetchAttendeeEventDetailsData = async () => {
            try {
                await store.dispatch('attendeeEvent/fetchAttendeeEventDetailsData', eventId);
                event.value = store.getters['attendeeEvent/attendeeEventDetailsData'];
                requestSongs.value = store.getters['attendeeEvent/getRequestedSongsData'];
                loading.value = false;
            } catch (error) {
                console.error(error);
            }
        };
        
        onMounted(async () => {
            await fetchAttendeeEventDetailsData();
        });

        const goBack = () => {
            router.go(-1);
        }

        watch(() => store.getters['attendeeEvent/getRequestedSongsData'], (newValue) => {
            requestSongs.value = newValue;
        });

        const joinEvent = async () => {
            swal({
                title: 'Join',
                text: 'Are you sure to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#6A04BA',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Join',
                showConfirmButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (event.value.code) {
                        const eventData = {
                            eventCode: event.value.code,
                            userId: store.getters['user/getUserId'],
                        };
                        const response = await store.dispatch('attendeeEvent/joinEvent', eventData);
			event.value.joined = 1
                        if (response.data.status) {
                            toast.success(response.data.msg);
                            event.value.joined = 1;
                        } else {
                            toast.error(response.data.msg);
                        }
                    } else {
                        toast.error('No code found.');
                    }
                }
            })
        }
        const leaveEvent = async () => {
            swal({
                title: 'Leave',
                text: 'Are you sure to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#6A04BA',
                confirmButtonText: 'Leave',
                showConfirmButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (event.value.code) {
                        const eventData = {
                            eventCode: event.value.code,
                            userId: store.getters['user/getUserId'],
                        };
                        const response = await store.dispatch('attendeeEvent/leaveEvent', eventData);
                        if (response.data.status) {
                            toast.success(response.data.msg);
                            event.value.joined = 0;
                        } else {
                            toast.error(response.data.msg);
                        }
                    } else {
                        toast.error('No code found.');
                    }
                }
            })
        }

        return {
            event,
            searchTerm,
            requestSongs,
            openModal,
            isRated,
            loading,
            goBack,
            joinEvent,
            role,
            loginAlert,
            leaveEvent
        }
    }
})
</script>
<style scoped>
.overlay {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    background: #000000b0;
}

.profile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-title {
    font-size: 32px;
    font-weight: bold;
}

.tab-title {
    border-radius: 100px;
}

.song-name {
    font-weight: 600;
}

.card-body {
    height: 100%;
}

.dropdown-profile-image {
    top: 80px;
    left: 37%;
}

.host-profile-image {
    width: 80px;
    height: 80px;
}

.host-cover-image {
    width: 100%;
}

.view-profile {
    width: 94%;
}

@media (max-width: 992px) {
    .event-title {
        font-size: 22px;
        font-weight: bold;
    }


    .event-title {
        font-size: 22px;
        font-weight: bold;
    }

    .modal-content-wrapper {
        flex-direction: column !important;
        align-items: center;
        gap: 50px;
    }

    .border-content {
        display: none !important;
    }


}
</style>
