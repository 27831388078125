import axios from 'axios';
import config from '../../config/config';
import { tokenExpired, pageNotFound } from '@/assets/js/utils';
const apiEndpoint = config.API_ENDPOINT;

const state = {
    joinedEventsData: [],
    songSearchRequestData: [],
    attendeeEventDetailsData: {},
    requestedSongsData: [],
    isRated: false,
    enrolledEvents: [],
};
const getters = {
    songSearchRequestData: (state) => state.songSearchRequestData,
    getRequestedSongsData: (state) => state.requestedSongsData,
    getJoinedEventsData: (state) => state.joinedEventsData,
    attendeeEventDetailsData: (state) => state.attendeeEventDetailsData,
    getIsRatedData: (state) => state.isRated,
    getAttendeeEnrolledEvents: (state) => state.enrolledEvents,
};
const actions = {
    async fetchSongRequest({ commit, rootGetters }, {artistTerm, searchTerm}) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(`${apiEndpoint}/api/v1/get-search-song-list?q=${artistTerm + ' ' + searchTerm}`);
            commit('setSearchRequestData', response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async fetchJoinedEventsData({ commit, rootGetters }) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(`${apiEndpoint}/api/v1/get-joined-events?attendee_id=${rootGetters['user/getUserId']}`);
            commit('setJoinedEventsData', response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async joinEvent({ commit, rootGetters }, eventData) {
        const formData = new FormData();
        formData.append('event_code', eventData.eventCode);
        formData.append('attendee_id', eventData.userId);
        formData.append('attendee_name', rootGetters['user/getUser'].name);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/join-event', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('joinEvent', response.data.data);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async fetchAttendeeEventDetailsData({ commit, rootGetters }, eventId) {
        try {
            // commit('setAttendeeEventDetailsData', []);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(apiEndpoint + `/api/v1/get-attendee-event-details?id=${eventId}&attendee_id=${rootGetters['user/getUserId']}`);
            commit('setAttendeeEventDetailsData', response.data.data);
            commit('setRequestedSongs', response.data.data.song_requests);
            commit('setIsRatedData', response.data.data.is_rated);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else if (error.response && error.response.status === 404) {
                pageNotFound();
            } else {
                return error.response;
            }
        }
    },
    async sendSongRequest({ commit, rootGetters }, songRequestData) {
        const formData = new FormData();
        formData.append('event_id', songRequestData.eventId);
        formData.append('attendee_id', songRequestData.attendeeId);
        formData.append('song_id', songRequestData.songId);
        formData.append('song_name', songRequestData.songName);
        formData.append('song_image', songRequestData.songImage);
        formData.append('song_link', songRequestData.songLink);
        formData.append('song_duration', songRequestData.songDuration);
        formData.append('song_artist', songRequestData.songArtist);
        formData.append('attendee_name', rootGetters['user/getUser'].name);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/send-song-request', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('sendSongRequest', response.data.data);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async cancelSongRequest({ commit, rootGetters }, body) {
        const formData = new FormData();
        formData.append('song_id', body.song_id);
        formData.append('event_id', body.event_id);
        formData.append('attendee_id', rootGetters['user/getUserId']);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/cancel-song-request', formData);
            commit('cancelSongRequest', body.song_id);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async storeHostRating({ commit, rootGetters }, eventData) {
        const formData = new FormData();
        formData.append('host_id', eventData.hostId);
        formData.append('attendee_id', rootGetters['user/getUserId']);
        formData.append('rating', eventData.ratingNumber);
        formData.append('feedback', eventData.feedBack);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/store-rating', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setIsRatedData', true);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async fetchAttendeeEnrolledEvents({ commit, rootGetters }, attendeeId) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(`${apiEndpoint}/api/v1/get-enrolled-event-list?attendee_id=${attendeeId}`);
            commit('setAttendeeEnrolledEvents', response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
};
const mutations = {
    setSearchRequestData(state, data) {
        state.songSearchRequestData = data;
    },
    setJoinedEventsData(state, data) {
        state.joinedEventsData = data;
    },
    joinEvent(state, newEvent) {
        state.enrolledEvents.push(newEvent);
    },
    setAttendeeEventDetailsData(state, data) {
        state.attendeeEventDetailsData = data;
    },
    sendSongRequest(state, data) {
        state.requestedSongsData.push(data);
    },
    setRequestedSongs(state, data) {
        state.requestedSongsData = data;
    },
    setIsRatedData(state, data) {
        state.isRated = data;
    },
    setAttendeeEnrolledEvents: (state, enrolledEvents) => (state.enrolledEvents = enrolledEvents),
    cancelSongRequest: (state, songId) => {
        state.requestedSongsData = state.requestedSongsData.filter((song) => song.song_id != songId);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
